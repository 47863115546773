import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { signNowCredentialInfoState } from 'src/state/atoms/integrationSettingState';
import { useIntegrationsApi } from 'src/hooks/api/useIntegrationsApi';
import './IntegrationPartnerPage.scss';
import { getIpDisplayText } from '../../../../utils/Account';
import { IpName } from '../../../../shared';
import SettingsContentHeader from '../../SettingsContentHeader';
import ADPSetUpCard from '../components/ADPSetUpCard';
import IntegrationClientConnectionModal from '../components/IntegrationClientConnectionModal';
import IntegrationAPIConnectionModal from '../components/IntegrationAPIConnectionModal';
import CollectionMapping from '../components/CollectionMapping/CollectionMapping';
import IpUserMatchingModal from '../components/IpUserMatchingModal';
import { ADP_IP_COLLECION_NAME } from '../constant';
import { getIntegrationPartnerConditions } from '../utils';
import IPPHeading from '../components/IPPHeading';
import IntegrationConnectionConfiguration from '../components/IntegrationConnectionConfiguration';
var LoadingIcon = _jsx(LoadingOutlined, { spin: true });
var IntegrationPartnerPage = function () {
    var _a, _b;
    var _c = useParams().ipName, ipName = _c === void 0 ? '' : _c;
    var _d = useIntegrationsApi(), useConnectToIp = _d.useConnectToIp, useConnectToSignNow = _d.useConnectToSignNow, usePeriodicResync = _d.usePeriodicResync, useDisconnectIntegration = _d.useDisconnectIntegration;
    var _e = usePeriodicResync(), resync = _e.mutate, isResyncing = _e.isLoading;
    var _f = useDisconnectIntegration(), disconnectIntegration = _f.mutate, isDisconnecting = _f.isLoading;
    var signNowCredentialInfo = useRecoilValue(signNowCredentialInfoState);
    var _g = useState(false), isConnected = _g[0], setIsConnected = _g[1];
    var _h = useState(''), connectUrl = _h[0], setConnectUrl = _h[1];
    var _j = useState(false), isClientConnectionModalVisible = _j[0], setIsClientConnectionModalVisible = _j[1];
    var _k = useState(false), isAPIConnectionModalVisible = _k[0], setIsAPIConnectionModalVisible = _k[1];
    var _l = useState(false), adpUserMatchModalVisible = _l[0], setAdpUserMatchModalVisible = _l[1];
    var _m = getIntegrationPartnerConditions(ipName), isSignNow = _m.isSignNow, hideIntegrationSync = _m.hideIntegrationSync;
    var _o = useConnectToIp({
        options: {
            enabled: !isSignNow,
        },
    }, ipName), connectToIpData = _o.data, isLoading = _o.isLoading;
    var signNowConnectionData = useConnectToSignNow({
        body: {
            apiKey: (_a = signNowCredentialInfo === null || signNowCredentialInfo === void 0 ? void 0 : signNowCredentialInfo.apiKey) !== null && _a !== void 0 ? _a : '',
            clientId: (_b = signNowCredentialInfo === null || signNowCredentialInfo === void 0 ? void 0 : signNowCredentialInfo.clientId) !== null && _b !== void 0 ? _b : '',
        },
        options: {
            enabled: isSignNow && !!signNowCredentialInfo,
        },
    }, ipName).data;
    var onDisconnectIntegration = function (ipName) {
        disconnectIntegration(ipName, {
            onSuccess: function () {
                setIsConnected(false);
                setConnectUrl('');
            },
        });
    };
    useEffect(function () {
        if (connectToIpData && !isSignNow) {
            setConnectUrl(connectToIpData.data);
            setIsConnected(connectToIpData.isConnected);
        }
        else if (signNowConnectionData && isSignNow) {
            setConnectUrl(signNowConnectionData.data);
            setIsConnected(signNowConnectionData.isConnected);
        }
    }, [connectToIpData, isSignNow, signNowConnectionData]);
    return (_jsxs(_Fragment, { children: [_jsxs(Spin, { spinning: isLoading || isResyncing || isDisconnecting, size: "large", indicator: LoadingIcon, children: [_jsxs(SettingsContentHeader, { children: [_jsx(Breadcrumb.Item, { children: _jsx(Link, { to: "/settings/integrations", children: "Integrations" }) }), _jsx(Breadcrumb.Item, { children: getIpDisplayText(ipName) })] }), _jsxs("div", { className: "IntegrationLayoutWrapper pb-8", children: [_jsx(IPPHeading, { ipName: ipName, isConnected: isConnected !== null && isConnected !== void 0 ? isConnected : false, isLoading: isLoading, resync: resync, disconnectIntegration: onDisconnectIntegration, connectUrl: connectUrl, setAdpUserMatchModalVisible: setAdpUserMatchModalVisible, setIsClientConnectionModalVisible: setIsClientConnectionModalVisible, setIsAPIConnectionModalVisible: setIsAPIConnectionModalVisible }), _jsxs("section", { className: "flex flex-col px-40 gap-10 items-center justify-center w-full", children: [isConnected && !isLoading && !hideIntegrationSync && (_jsxs(_Fragment, { children: [_jsx(IntegrationConnectionConfiguration, { ipName: ipName }), _jsx(CollectionMapping, { ipName: ipName })] })), !isConnected && !isLoading && (_jsx(_Fragment, { children: ipName === IpName.ADP && _jsx(ADPSetUpCard, {}) }))] })] })] }), adpUserMatchModalVisible && (_jsx(IpUserMatchingModal, { ipCollection: ADP_IP_COLLECION_NAME, isOpen: adpUserMatchModalVisible, closeModal: function () { return setAdpUserMatchModalVisible(false); } })), isClientConnectionModalVisible && (_jsx(IntegrationClientConnectionModal, { ipName: ipName, onCloseModal: function () { return setIsClientConnectionModalVisible(false); }, updateConnectionStatus: function () { return setIsConnected(true); } })), isAPIConnectionModalVisible && (_jsx(IntegrationAPIConnectionModal, { ipName: ipName, onCloseModal: function () { return setIsAPIConnectionModalVisible(false); }, updateConnectionStatus: function () { return setIsConnected(true); } }))] }));
};
export default React.memo(IntegrationPartnerPage);
