var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useNeedCoverageApi } from 'src/hooks/api/useNeedCoverageApi';
import { tableCollectionIdSelector, tableDisplaySettingsState, collectionTableNonSchemaNativeFieldsState, scheduleBudgetedTemplateStatsSelector, scheduleTableState, scheduleTimeRangeState, scheduleTimeZoneSelector, } from 'src/state';
import { shiftTemplateListRespState } from 'src/components/ScheduleView/NewShiftTemplate/Atom';
import { CollectionTableType } from 'src/shared';
import moment from 'moment';
import { getIsNeedsOverfilled, getIsNeedsUnderfilled, getScheduleTemplateTotalsCountStat, } from '../../../Utils';
import { ShowNeedVisibilityOption } from '../../../Constant';
import ScheduleTemplateCountStatRow from './ScheduleTemplateCountStatRow';
import './ScheduleTemplateCountStats.scss';
var ScheduleTemplateCountStats = function (_a) {
    var blockId = _a.blockId, widths = _a.widths, showNeedVisibilityOption = _a.showNeedVisibilityOption;
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var displaySettings = useRecoilValue(tableDisplaySettingsState(blockId));
    var timeZone = useRecoilValue(scheduleTimeZoneSelector(blockId));
    var table = useRecoilValue(scheduleTableState(blockId));
    var collectionId = useRecoilValue(tableCollectionIdSelector(blockId));
    var nonSchemaNativeFields = useRecoilValue(collectionTableNonSchemaNativeFieldsState({ type: CollectionTableType.TABLE, collectionId: collectionId }));
    var templateListResp = useRecoilValue(shiftTemplateListRespState(blockId));
    var isBudgetedStats = useRecoilValue(scheduleBudgetedTemplateStatsSelector(blockId));
    var useGetScheduleTemplateCountStats = useNeedCoverageApi().useGetScheduleTemplateCountStats;
    var _b = useGetScheduleTemplateCountStats({
        body: {
            blockId: blockId,
            displaySettings: displaySettings,
            timeZone: timeZone,
            startFrom: moment(timeRange[0]).format('YYYY-MM-DD'),
            endAt: moment(timeRange[timeRange.length - 2]).format('YYYY-MM-DD'),
        },
    }), scheduleTemplateCountStats = _b.data, isFetching = _b.isFetching, refetch = _b.refetch;
    useEffect(function () {
        if (table) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table, nonSchemaNativeFields]);
    var filteredScheduleTemplateCountStats = useMemo(function () {
        var _a, _b;
        var templateList = (_a = templateListResp === null || templateListResp === void 0 ? void 0 : templateListResp.data) !== null && _a !== void 0 ? _a : {};
        return ((_b = scheduleTemplateCountStats === null || scheduleTemplateCountStats === void 0 ? void 0 : scheduleTemplateCountStats.data.reduce(function (allCounts, templateCount) {
            var templateId = templateCount.templateId, stats = templateCount.stats;
            // When ShowNeedVisibilityOption is not ALL, filter out rows that don't have needs that match the option
            if (showNeedVisibilityOption != ShowNeedVisibilityOption.ALL) {
                var filterFn_1 = showNeedVisibilityOption === ShowNeedVisibilityOption.UNDERFILLED
                    ? getIsNeedsUnderfilled
                    : getIsNeedsOverfilled;
                var filteredStats = Object.fromEntries(Object.entries(stats).filter(function (_a) {
                    var _ = _a[0], stat = _a[1];
                    return filterFn_1(stat.actual, stat.expected);
                }));
                if (Object.keys(filteredStats).length === 0) {
                    return allCounts;
                }
            }
            if (templateId) {
                // Template row
                var template = templateList[templateId];
                if (template) {
                    // Template found (has permission to view)
                    allCounts.push(__assign(__assign({}, templateCount), { name: template.name, color: template.color }));
                }
            }
            else {
                // "No Template" row
                var hasAtLeastOneStat = Object.values(stats).some(function (stat) { return stat.actual > 0; });
                if (hasAtLeastOneStat) {
                    allCounts.push(__assign(__assign({}, templateCount), { name: 'No Template' }));
                }
            }
            return allCounts;
        }, [])) !== null && _b !== void 0 ? _b : []);
    }, [scheduleTemplateCountStats === null || scheduleTemplateCountStats === void 0 ? void 0 : scheduleTemplateCountStats.data, templateListResp, showNeedVisibilityOption]);
    var scheduleTemplateTotalsCountStat = useMemo(function () {
        return getScheduleTemplateTotalsCountStat(filteredScheduleTemplateCountStats, isBudgetedStats);
    }, [filteredScheduleTemplateCountStats, isBudgetedStats]);
    // Reorder stats list based on template list order
    var filteredScheduleTemplateCountStatsInOrder = useMemo(function () {
        var _a;
        if (!((_a = templateListResp === null || templateListResp === void 0 ? void 0 : templateListResp.settings) === null || _a === void 0 ? void 0 : _a.order)) {
            return filteredScheduleTemplateCountStats;
        }
        else {
            var order = templateListResp.settings.order;
            var newOrderedList_1 = [];
            order.map(function (id) {
                var template = filteredScheduleTemplateCountStats.find(function (template) { return template.templateId === id; });
                if (template) {
                    newOrderedList_1.push(template);
                }
            });
            // Attach "No Template" row to the end
            var noTemplate = filteredScheduleTemplateCountStats.find(function (template) { return template.name === 'No Template'; });
            if (noTemplate) {
                newOrderedList_1.push(noTemplate);
            }
            return newOrderedList_1;
        }
    }, [filteredScheduleTemplateCountStats, templateListResp]);
    if (isFetching) {
        return (_jsx("div", { className: "grid h-full w-full place-items-center text-lg font-semibold", children: "Loading..." }));
    }
    return (_jsxs("div", { className: "SummaryScrollContainer", style: { width: widths.tableWidth }, children: [showNeedVisibilityOption == ShowNeedVisibilityOption.ALL && (_jsx(ScheduleTemplateCountStatRow, { blockId: blockId, id: scheduleTemplateTotalsCountStat.templateId, name: "Totals", stats: scheduleTemplateTotalsCountStat.stats, widths: widths, canEditExpected: false, showNeedVisibilityOption: showNeedVisibilityOption })), filteredScheduleTemplateCountStatsInOrder.map(function (_a) {
                var templateId = _a.templateId, stats = _a.stats, name = _a.name, color = _a.color;
                return (_jsx(ScheduleTemplateCountStatRow, { blockId: blockId, id: templateId, name: name, color: color, stats: stats, widths: widths, canEditExpected: !isBudgetedStats && !!templateId, showNeedVisibilityOption: showNeedVisibilityOption }, templateId));
            })] }));
};
export default React.memo(ScheduleTemplateCountStats);
