import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ScheduleView } from 'src/services';
import { scheduleBudgetedTemplateStatsSelector, scheduleTimeRangeState, scheduleViewTypeSelector, } from 'src/state';
import { useRecoilValue } from 'recoil';
import { MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, WEEK_VIEW_DATE_COLUMN_MIN_WIDTH, } from 'src/components/ScheduleView/Constant';
import { getTagTextColor } from 'src/utils/Collection';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import moment from 'moment';
import ScheduleTemplateCountStatCell from './ScheduleTemplateCountStatCell';
var ROW_HEIGHT = 38;
var ScheduleTemplateCountStatRow = function (_a) {
    var blockId = _a.blockId, id = _a.id, name = _a.name, color = _a.color, stats = _a.stats, widths = _a.widths, canEditExpected = _a.canEditExpected, showNeedVisibilityOption = _a.showNeedVisibilityOption;
    var tableWidth = widths.tableWidth, tableFirstColumnWith = widths.tableFirstColumnWith;
    var isBudgetedStats = useRecoilValue(scheduleBudgetedTemplateStatsSelector(blockId));
    var scheduleView = useRecoilValue(scheduleViewTypeSelector(blockId));
    var timeRange = useRecoilValue(scheduleTimeRangeState(blockId));
    var cellMinWidth = scheduleView !== ScheduleView.WEEK
        ? MULTI_WEEK_VIEW_DATE_COLUMN_MIN_WIDTH
        : WEEK_VIEW_DATE_COLUMN_MIN_WIDTH;
    return (_jsxs("div", { className: "flex items-center", children: [_jsx("div", { className: "sticky left-0 z-[1] flex items-center border-b border-r border-[#f0f0f0] bg-white pl-[8px]", style: {
                    height: ROW_HEIGHT,
                    width: tableFirstColumnWith,
                    minWidth: tableFirstColumnWith,
                }, children: _jsx(ZiraTooltip, { title: name, children: _jsx("div", { className: "m-[4px] overflow-hidden text-ellipsis whitespace-nowrap rounded bg-white px-[8px] py-[2px] font-medium", style: {
                            backgroundColor: color,
                            color: getTagTextColor(color),
                        }, children: name }) }) }), _jsx("div", { className: "grid flex-shrink-0", style: {
                    width: "calc(".concat(tableWidth, " - ").concat(tableFirstColumnWith, "px)"),
                    gridTemplateColumns: "repeat(".concat(Object.values(stats).length, ", minmax(").concat(cellMinWidth, "px, 1fr))"),
                    height: ROW_HEIGHT,
                }, children: Object.values(stats).map(function (_a, idx) {
                    var actual = _a.actual, expected = _a.expected, manuallyUpdated = _a.manuallyUpdated, openShifts = _a.openShifts;
                    var date = moment(timeRange[idx]).format('YYYY-MM-DD');
                    return (_jsx(ScheduleTemplateCountStatCell, { id: id, blockId: blockId, name: name, actual: actual, expected: expected, manuallyUpdatedInfo: !isBudgetedStats ? manuallyUpdated : undefined, date: date, canEditExpected: canEditExpected, openShifts: openShifts, showNeedVisibilityOption: showNeedVisibilityOption }, "".concat(id, "-").concat(idx)));
                }) })] }));
};
export default React.memo(ScheduleTemplateCountStatRow);
