var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NeedsCoverageIcon } from 'src/utils/icons/SchedulingIcons/NeedsCoverageIcon';
import { SchedulingStatsIcon } from 'src/utils/icons/SchedulingIcons/SchedulingStatsIcon';
import { UsersIcon } from 'src/utils/icons/DashboardIcons/UserIcon';
import { v4 as uuidv4 } from 'uuid';
import { SchedulingSummaryType } from './interface';
export var defaultSchedulingBottomSectionTabSetting = {
    SCHEDULING_STATS: true,
    NEEDS_AND_COVERAGE: false,
    DEMAND_RATIO: false,
};
export var DEFAULT_SCHEDULE_BOTTOM_BAR_SETTINGS = {
    bottomBarTabs: defaultSchedulingBottomSectionTabSetting,
    budgetedTemplateStats: false,
};
export var scheduleBottomBarTabsMap = (_a = {},
    _a[SchedulingSummaryType.SCHEDULING_STATS] = {
        name: 'Stats',
        icon: _jsx(SchedulingStatsIcon, {}),
    },
    _a[SchedulingSummaryType.NEEDS_AND_COVERAGE] = {
        name: 'Needs & Coverage',
        icon: _jsx(NeedsCoverageIcon, {}),
    },
    _a[SchedulingSummaryType.DEMAND_RATIO] = {
        name: 'Demand Ratio',
        icon: _jsx(UsersIcon, { style: { width: '14px' } }),
    },
    _a);
export var templateStatusOptions = [
    {
        id: 'ACTIVE',
        text: 'Active',
        color: '#E5F4EF',
    },
    {
        id: 'ARCHIVED',
        text: 'Archived',
        color: '#FFEDEC',
    },
];
export var renderSelectorCellWarningContent = function (type) {
    return (_jsxs("div", { className: "flex items-center", children: [_jsx(ExclamationCircleOutlined, { style: {
                    color: '#FFC047',
                    fontSize: '16px',
                    stroke: '#FFC047',
                    strokeWidth: '12',
                } }), _jsx("div", { className: "ml-3 text-xs font-medium", children: "".concat(type, " is required, please select one or delete this record.") })] }));
};
export var DEFAULT_NEED_TEMPLATE_DAY_OF_WEEK_DATA = [
    {
        date: 'MONDAY',
        need: 0,
    },
    {
        date: 'TUESDAY',
        need: 0,
    },
    {
        date: 'WEDNESDAY',
        need: 0,
    },
    {
        date: 'THURSDAY',
        need: 0,
    },
    {
        date: 'FRIDAY',
        need: 0,
    },
    {
        date: 'SATURDAY',
        need: 0,
    },
    {
        date: 'SUNDAY',
        need: 0,
    },
];
export var DEFAULT_NEED_TEMPLATE_DETAIL_TABLE_RECORD = {
    id: uuidv4(),
    templateId: undefined,
    interval: 1,
    data: [DEFAULT_NEED_TEMPLATE_DAY_OF_WEEK_DATA],
};
export var DEFAULT_COVERAGE_TEMPLATE_DAY_OF_WEEK_DATA = [
    {
        date: 'MONDAY',
        templateIds: [],
        templates: [],
    },
    {
        date: 'TUESDAY',
        templateIds: [],
        templates: [],
    },
    {
        date: 'WEDNESDAY',
        templateIds: [],
        templates: [],
    },
    {
        date: 'THURSDAY',
        templateIds: [],
        templates: [],
    },
    {
        date: 'FRIDAY',
        templateIds: [],
        templates: [],
    },
    {
        date: 'SATURDAY',
        templateIds: [],
        templates: [],
    },
    {
        date: 'SUNDAY',
        templateIds: [],
        templates: [],
    },
];
export var DEFAULT_COVERAGE_TEMPLATE_DETAIL_TABLE_RECORD = {
    id: uuidv4(),
    templateId: undefined,
    interval: 1,
    data: [DEFAULT_COVERAGE_TEMPLATE_DAY_OF_WEEK_DATA],
};
export var NEED_COVERAGE_TEMPLATE_TABLE_FIRST_COLUMN_WIDTH = 250;
export var ShowNeedVisibilityOption;
(function (ShowNeedVisibilityOption) {
    ShowNeedVisibilityOption["ALL"] = "All";
    ShowNeedVisibilityOption["UNDERFILLED"] = "Underfilled";
    ShowNeedVisibilityOption["OVERFILLED"] = "Overfilled";
})(ShowNeedVisibilityOption || (ShowNeedVisibilityOption = {}));
