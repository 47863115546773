var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { useIntegrationsApi } from 'src/hooks/api/useIntegrationsApi';
import { Button, Select, Switch } from 'antd';
import Input from 'antd/lib/input/Input';
import { IpConfigSettingValueType, } from 'src/services/Interfaces';
var IntegrationConnectionConfiguration = function (_a) {
    var ipName = _a.ipName;
    var _b = useIntegrationsApi(), useGetIpConfigurationSettings = _b.useGetIpConfigurationSettings, useSaveIpConfigurationSettings = _b.useSaveIpConfigurationSettings;
    var isLoadingSettings = useGetIpConfigurationSettings(ipName.toLowerCase(), {
        onSuccess: function (settings) {
            setSettings(settings !== null && settings !== void 0 ? settings : {});
        },
    }).isLoading;
    var _c = useSaveIpConfigurationSettings(ipName), saveIpConfigurationSettings = _c.mutate, isSaving = _c.isLoading;
    var _d = useState({}), settings = _d[0], setSettings = _d[1];
    var handleUpdateSetting = useCallback(function (internalName, newSetting) {
        setSettings(function (prevSettings) {
            var _a;
            return (__assign(__assign({}, prevSettings), (_a = {}, _a[internalName] = newSetting, _a)));
        });
    }, [setSettings]);
    var handleSaveSettings = useCallback(function (e) {
        e.preventDefault();
        var newSettings = Object.keys(settings).reduce(function (acc, key) {
            var setting = settings[key];
            acc[key] = {
                value: setting.value,
                unit: setting.unit,
            };
            return acc;
        }, {});
        saveIpConfigurationSettings(newSettings);
    }, [saveIpConfigurationSettings, settings]);
    var renderSettingType = useCallback(function (setting) {
        switch (setting.valueType) {
            case IpConfigSettingValueType.NUMBER: {
                var numVal = setting.value;
                return (_jsxs("div", { className: "flex flex-row gap-2 items-center align-items", children: [_jsx(Input, { value: numVal, type: "number", className: "max-w-[80px]", onChange: function (_a) {
                                var target = _a.target;
                                var strVal = target.value;
                                handleUpdateSetting(setting.internalName, __assign(__assign({}, setting), { value: parseInt(strVal) }));
                            } }), setting.unitOptions && setting.unitOptions.length > 0 && (_jsx(Select, { dropdownStyle: { minWidth: 'min-content' }, className: "min-w-[80px]", value: setting.unit, options: setting.unitOptions.map(function (unit) { return ({
                                label: "".concat(unit, "s"),
                                value: unit,
                            }); }), onChange: function (unit) {
                                handleUpdateSetting(setting.internalName, __assign(__assign({}, setting), { unit: unit }));
                            } }))] }));
            }
            case IpConfigSettingValueType.BOOLEAN: {
                var boolVal = setting.value;
                return (_jsx(Switch, { checked: boolVal, onChange: function (checked) {
                        handleUpdateSetting(setting.internalName, __assign(__assign({}, setting), { value: checked }));
                    } }));
            }
            default: {
                return null;
            }
        }
    }, [handleUpdateSetting]);
    if (isLoadingSettings)
        return _jsx(_Fragment, {});
    return (_jsxs("section", { className: "flex flex-col gap-4 w-full", children: [_jsx("h2", { className: "text-lg font-semibold", children: "Configure Connection" }), Object.keys(settings).length > 0 ? (_jsxs("form", { className: "w-full flex flex-col", onSubmit: handleSaveSettings, children: [_jsx("div", { className: "divide-y divide-gray-50", children: Object.keys(settings).map(function (key) {
                            var setting = settings[key];
                            return (_jsxs("div", { className: "flex py-3 items-center justify-between", children: [_jsxs("div", { className: "flex flex-col gap-2", children: [_jsx("span", { className: "text-md font-medium", children: setting.displayName }), _jsx("span", { className: "text-xs font-medium text-gray-500", children: setting.description })] }), _jsx("div", { children: renderSettingType(setting) })] }, setting.internalName));
                        }) }), _jsx(Button, { className: "self-end mt-2 text-xs px-2 py-1 font-semibold text-white bg-blue-400 hover:bg-blue-400 hover:text-gray-50", htmlType: "submit", disabled: isSaving, children: "Save Changes" })] })) : (_jsx("p", { className: "text-gray-500", children: "No custom configuration settings for this integration" }))] }));
};
export default IntegrationConnectionConfiguration;
