import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HelpArticleType, HelpDrawerLinkedArticleMap, } from 'src/components/CenterPanel/Header/HelpDrawer/Constant';
import HelpActionWrapper from 'src/components/CenterPanel/Header/HelpDrawer/HelpActionWrapper';
import { getIntegrationIcon, getIpDisplayText } from 'src/utils/Account';
import { HelpIcon } from 'src/utils/icons/HelpIcon';
import { Button, Tag } from 'antd';
import { IpName } from 'src/shared';
import { ReconciliationOutlined, SyncOutlined } from '@ant-design/icons';
import { DisconnectIcon } from 'src/utils/icons/DisconnectIcon';
import IntegrationDetails from '../components/IntegrationDetails';
import { getIntegrationPartnerConditions } from '../utils';
var IPPHeading = function (_a) {
    var ipName = _a.ipName, isConnected = _a.isConnected, isLoading = _a.isLoading, connectUrl = _a.connectUrl, resync = _a.resync, disconnectIntegration = _a.disconnectIntegration, setAdpUserMatchModalVisible = _a.setAdpUserMatchModalVisible, setIsClientConnectionModalVisible = _a.setIsClientConnectionModalVisible, setIsAPIConnectionModalVisible = _a.setIsAPIConnectionModalVisible;
    var _b = getIntegrationPartnerConditions(ipName), requiresIntegrationClientConnection = _b.requiresIntegrationClientConnection, requiresIntegrationAPIConnection = _b.requiresIntegrationAPIConnection, hideIntegrationSync = _b.hideIntegrationSync;
    return (_jsxs("section", { className: "flex flex-col items-center gap-4", children: [_jsx("div", { children: getIntegrationIcon(ipName, 'max-h-[83px]') }), _jsxs("div", { className: "flex flex-col align-start w-1/2 gap-2", children: [_jsxs("div", { className: "flex flex-row align-center items-center", children: [_jsx("h2", { className: "text-[16px] font-semibold", children: getIpDisplayText(ipName) }), ipName === 'docusign' && (_jsx(HelpActionWrapper, { articleId: HelpDrawerLinkedArticleMap[HelpArticleType.DOCUSIGN_SETUP], children: _jsx(HelpIcon, { className: "ml-2 h-[16px] text-black" }) }))] }), _jsx(IntegrationDetails, { ipName: ipName })] }), isConnected && (_jsxs("div", { className: "flex flex-col items-center gap-4", children: [_jsxs("div", { className: "flex text-xs items-center gap-1", children: [_jsx("span", { className: "text-green-400 font-semibold", children: "Status: " }), _jsx(Tag, { className: "rounded-md bg-green-50 font-medium text-green-400 border-green-400 border-1", children: "Connected" })] }), _jsxs("div", { className: "flex items-center gap-3", children: [_jsx(Button, { danger: true, type: "primary", className: "px-3 font-semibold", style: { textShadow: 'none' }, icon: _jsx(DisconnectIcon, { className: "mb-[1px]" }), onClick: function () {
                                    disconnectIntegration(ipName);
                                }, children: "Disconnect" }), !hideIntegrationSync && (_jsx(Button, { type: "primary", color: "gray", className: "px-3 font-semibold", style: { textShadow: 'none' }, icon: _jsx(SyncOutlined, { className: "mb-1" }), onClick: function () { return resync(ipName); }, children: "Sync Now" })), ipName === IpName.ADP && (_jsx(Button, { type: "primary", color: "gray", className: "px-3 font-semibold", style: { textShadow: 'none' }, icon: _jsx(ReconciliationOutlined, { className: "mb-1" }), onClick: function () {
                                    setAdpUserMatchModalVisible(true);
                                }, children: "Reconcile Pending Users" }))] })] })), !isConnected && !isLoading && (_jsx(_Fragment, { children: _jsx("div", { className: "my-[16px] flex items-center", children: ipName !== IpName.ADP && (_jsx(Button, { className: "bg-black text-white font-semibold hover:bg-black hover:text-white", onClick: function () {
                            if (requiresIntegrationClientConnection) {
                                setIsClientConnectionModalVisible(true);
                                return;
                            }
                            else if (requiresIntegrationAPIConnection) {
                                setIsAPIConnectionModalVisible(true);
                                return;
                            }
                            window.location.href = connectUrl;
                        }, children: "Connect" })) }) }))] }));
};
export default IPPHeading;
