var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useMemo, useState } from 'react';
import { Button } from 'antd';
import { useRecoilValue } from 'recoil';
import { currentPageIdSelector } from 'src/state';
import { CollectionType, defaultNewCustomCollectionIdentifier, newCustomCollectionCard, } from 'src/shared';
import { useMetricsBarComponent, usePage } from 'src/hooks/component';
import { UploadUrlType, usePageApi } from 'src/hooks/api';
import ComponentWithUpload, { UploadFileType } from 'src/components/ComponentWithUpload';
import ReportModal from 'src/components/ReportBlock/ReportModal/ReportModal';
import PopupModalMetricConfig from 'src/components/PopupModalMetricConfig/PopupModalMetricConfig';
import AddNewCollectionModal from '../NewBlockDropdown/AddNewCollectionModal';
import ButtonBlockModal from '../NewBlockDropdown/ButtonBlockModal';
import BlockTemplateCard from './BlockTemplateCard';
var BlockCreationStep = function (props) {
    var _a;
    var availableCollection = props.availableCollection, selectedBlock = props.selectedBlock, isCreatingTableTypePage = props.isCreatingTableTypePage, onCloseDrawer = props.onCloseDrawer;
    var pageId = useRecoilValue(currentPageIdSelector);
    var _b = usePage(pageId), createCollection = _b.createCollection, createMarkdownBlock = _b.createMarkdownBlock, createImgBlock = _b.createImgBlock, createVideoBlock = _b.createVideoBlock, createScheduleBlock = _b.createScheduleBlock, createButtonBlock = _b.createButtonBlock, createReportBlock = _b.createReportBlock, createNewsFeedBlock = _b.createNewsFeedBlock, createShiftRequestBlock = _b.createShiftRequestBlock, createShiftReleaseApprovalBlock = _b.createShiftReleaseApprovalBlock, createMetricBarBlock = _b.createMetricBarBlock, createGeomapBlock = _b.createGeomapBlock, createCollectionFromTemplate = _b.createCollectionFromTemplate, createPayPeriodBlock = _b.createPayPeriodBlock, createHeadcountBlock = _b.createHeadcountBlock;
    var useGetCollectionTemplate = usePageApi().useGetCollectionTemplate;
    var collectionTemplates = useGetCollectionTemplate({
        collectionType: (_a = selectedBlock.collectionType) !== null && _a !== void 0 ? _a : '',
        options: {
            enabled: selectedBlock.fetchCollectionTemplates && !!selectedBlock.collectionType,
        },
    }).data;
    var shouldRenderContent = useMemo(function () {
        return (selectedBlock.fetchCollectionTemplates && !!collectionTemplates) ||
            !selectedBlock.fetchCollectionTemplates;
    }, [collectionTemplates, selectedBlock.fetchCollectionTemplates]);
    var _c = useState(), selectedTemplate = _c[0], setSelectedTemplate = _c[1];
    // add existing custom collection table
    var onCreateRefCollectionBlock = useCallback(function (collectionId, title) {
        onCloseDrawer();
        createCollection(title, {
            existingCollectionId: collectionId,
            newPageName: selectedBlock.blockName,
        });
    }, [createCollection, onCloseDrawer, selectedBlock.blockName]);
    // add new custom collection table
    var _d = useState(false), isCreateNewCollectionConfigVisible = _d[0], setCreateNewCollectionConfigVisible = _d[1];
    var onConfirmAddNewCollection = useCallback(function (values) {
        createCollection(values.tableName);
        onCloseDrawer();
    }, [createCollection, onCloseDrawer]);
    // add button widget
    var _e = useState(false), isAddNewButtonVisible = _e[0], setIsAddNewButtonVisible = _e[1];
    var onConfirmAddNewButton = useCallback(function (values) {
        setIsAddNewButtonVisible(false);
        createButtonBlock('', values.label, values.type, values.link);
        onCloseDrawer();
    }, [createButtonBlock, onCloseDrawer]);
    // add report
    var _f = useState(false), isAddNewReportVisible = _f[0], setIsAddNewReportVisible = _f[1];
    var onCreateReportBlock = useCallback(function (reportBuilderData) {
        var _a;
        var title = (_a = availableCollection.find(function (collection) {
            return collection.collectionId === reportBuilderData.settings.sourceCollectionId;
        })) === null || _a === void 0 ? void 0 : _a.blockName;
        createReportBlock("".concat(title, " Report"), reportBuilderData);
        onCloseDrawer();
    }, [availableCollection, createReportBlock, onCloseDrawer]);
    // add metrics bar
    var getAvailableMetrics = useMetricsBarComponent().getAvailableMetrics;
    var _g = useState(false), isAddNewMetricBarVisible = _g[0], setIsAddNewMetricBarVisible = _g[1];
    var onCreateNewMetricBar = useCallback(function (title, name, metric) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            if (!metric.dateRange)
                return [2 /*return*/];
            data = {
                title: title,
                metricName: name,
                filters: metric.filters,
                dateRange: metric.dateRange,
            };
            onCloseDrawer();
            createMetricBarBlock(data);
            return [2 /*return*/];
        });
    }); }, [createMetricBarBlock, onCloseDrawer]);
    // add video and img block
    var acceptedFileImgType = useMemo(function () { return [UploadFileType.IMAGE]; }, []);
    var acceptedFileVideoType = useMemo(function () { return [UploadFileType.VIDEO]; }, []);
    var onUploadImgSuccess = useCallback(function (info) {
        var url = info.file.response.data.url;
        onCloseDrawer();
        createImgBlock(url);
    }, [createImgBlock, onCloseDrawer]);
    var onUploadVideoSuccess = useCallback(function (info) {
        var url = info.file.response.data.url;
        onCloseDrawer();
        createVideoBlock(url);
    }, [createVideoBlock, onCloseDrawer]);
    var renderImageOrVideoUploadBtn = useCallback(function (isImage) {
        return (_jsx(ComponentWithUpload, { acceptedFileType: isImage ? acceptedFileImgType : acceptedFileVideoType, successAction: isImage ? onUploadImgSuccess : onUploadVideoSuccess, type: UploadUrlType.Block, children: _jsx(Button, { className: "AddBlockBtn mt-5", children: "Add Block To Page" }) }));
    }, [acceptedFileImgType, acceptedFileVideoType, onUploadImgSuccess, onUploadVideoSuccess]);
    var onAddBlockBtnClick = useCallback(function () {
        // add standard and new custom collection table
        if (selectedBlock.fetchCollectionTemplates && selectedTemplate) {
            // new custom collection table
            if (selectedTemplate.identifier === defaultNewCustomCollectionIdentifier) {
                setCreateNewCollectionConfigVisible(true);
            }
            // other collection
            else {
                onCloseDrawer();
                createCollectionFromTemplate(selectedTemplate.identifier, isCreatingTableTypePage ? selectedBlock.blockName : undefined);
            }
        }
        else {
            switch (selectedBlock.blockType) {
                // add existing custom collection
                case 'collection':
                    onCreateRefCollectionBlock(selectedBlock.collectionId, selectedBlock.blockName);
                    break;
                case 'button':
                    setIsAddNewButtonVisible(true);
                    break;
                case 'scheduling':
                    onCloseDrawer();
                    createScheduleBlock('Schedule');
                    break;
                case 'newsfeed':
                    onCloseDrawer();
                    createNewsFeedBlock();
                    break;
                case 'geomap':
                    onCloseDrawer();
                    createGeomapBlock();
                    break;
                case 'headcount':
                    onCloseDrawer();
                    createHeadcountBlock();
                    break;
                case 'pay_periods':
                    onCloseDrawer();
                    createPayPeriodBlock();
                    break;
                case 'report':
                    setIsAddNewReportVisible(true);
                    break;
                case 'shift_requests':
                    onCloseDrawer();
                    createShiftRequestBlock('Shift Claim Request');
                    break;
                case 'release_requests':
                    onCloseDrawer();
                    createShiftReleaseApprovalBlock('Shift Release Requests');
                    break;
                case 'markdown':
                    onCloseDrawer();
                    createMarkdownBlock();
                    break;
                case 'metric_bar':
                    getAvailableMetrics();
                    setIsAddNewMetricBarVisible(true);
                    break;
            }
        }
    }, [
        createCollectionFromTemplate,
        createGeomapBlock,
        createHeadcountBlock,
        createMarkdownBlock,
        createNewsFeedBlock,
        createPayPeriodBlock,
        createScheduleBlock,
        createShiftRequestBlock,
        createShiftReleaseApprovalBlock,
        getAvailableMetrics,
        isCreatingTableTypePage,
        onCloseDrawer,
        onCreateRefCollectionBlock,
        selectedBlock,
        selectedTemplate,
    ]);
    var addBlockBtn = useMemo(function () {
        if (selectedBlock.blockType === 'image' || selectedBlock.blockType === 'video') {
            return renderImageOrVideoUploadBtn(selectedBlock.blockType === 'image');
        }
        else {
            return (_jsx("div", { className: "absolute bottom-0 w-full px-5 left-0", children: _jsx(Button, { className: "AddBlockBtn mt-5 h-10 inline-block", onClick: onAddBlockBtnClick, disabled: selectedBlock.fetchCollectionTemplates && !selectedTemplate, children: isCreatingTableTypePage
                        ? 'Select Table as Workspace'
                        : 'Add Block To Page' }) }));
        }
    }, [
        isCreatingTableTypePage,
        onAddBlockBtnClick,
        renderImageOrVideoUploadBtn,
        selectedBlock.blockType,
        selectedBlock.fetchCollectionTemplates,
        selectedTemplate,
    ]);
    if (!shouldRenderContent)
        return _jsx(_Fragment, {});
    return (_jsxs("div", { className: "BlockSelectionStepContainer", children: [_jsx("div", { className: "mb-5 px-5", children: _jsx("div", { className: "text-gray-500", children: selectedBlock.blockDescription }) }), _jsxs("div", { className: "AllBlockCategoriesContent px-5", children: [selectedBlock.collectionType === CollectionType.CUSTOM &&
                        selectedBlock.fetchCollectionTemplates && (_jsx(BlockTemplateCard, { template: newCustomCollectionCard, selectedTemplate: selectedTemplate, onTemplateSelect: function () { return setSelectedTemplate(newCustomCollectionCard); } })), collectionTemplates === null || collectionTemplates === void 0 ? void 0 : collectionTemplates.collectionTemplates.map(function (template) {
                        return (_jsx(BlockTemplateCard, { template: template, selectedTemplate: selectedTemplate, onTemplateSelect: function () { return setSelectedTemplate(template); } }, "".concat(template.identifier, "-").concat(template.templateName)));
                    }), _jsx("div", { children: addBlockBtn })] }), isCreateNewCollectionConfigVisible && (_jsx(AddNewCollectionModal, { visible: isCreateNewCollectionConfigVisible, onCancel: function () { return setCreateNewCollectionConfigVisible(false); }, onOk: function (values) {
                    onConfirmAddNewCollection(values);
                    setCreateNewCollectionConfigVisible(false);
                } })), isAddNewButtonVisible && (_jsx(ButtonBlockModal, { visible: isAddNewButtonVisible, onCancel: function () { return setIsAddNewButtonVisible(false); }, onOk: onConfirmAddNewButton })), isAddNewReportVisible && (_jsx(ReportModal, { closeModal: function () { return setIsAddNewReportVisible(false); }, onCreateReport: onCreateReportBlock })), isAddNewMetricBarVisible && (_jsx(PopupModalMetricConfig, { isModalVisible: isAddNewMetricBarVisible, setIsModalVisible: function (visible) { return setIsAddNewMetricBarVisible(visible); }, onChangeMetricBar: onCreateNewMetricBar, isMetricBar: true }))] }));
};
export default React.memo(BlockCreationStep);
