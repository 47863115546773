var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Select, Switch } from 'antd';
import ZiraTooltip from 'src/ui/ZiraTooltip';
import { scheduleBottomBarSettingsSelector } from 'src/state';
import { useScheduleComponent } from 'src/hooks/component';
import { isNeedsCoverageConfigModalVisibleState } from '../../Atom';
import NeedsCoverageTemplateConfigModal from '../ConfigModal/NeedsCoverageTemplateConfigModal';
import { ShowNeedVisibilityOption } from '../../Constant';
import ScheduleNeedsSummaryInfo from './ScheduleNeedsSummaryInfo';
import ScheduleCoverageSummaryInfo from './ScheduleCoverageSummaryInfo';
import ScheduleTemplateCountStats from './ScheduleTemplateCountStats/ScheduleTemplateCountStats';
var ScheduleNeedsCoverageSummaryHeader = function (_a) {
    var blockId = _a.blockId, widths = _a.widths;
    var isConfigModalVisible = useRecoilValue(isNeedsCoverageConfigModalVisibleState);
    var bottomBarSettings = useRecoilValue(scheduleBottomBarSettingsSelector(blockId));
    var isBudgetedStats = bottomBarSettings.budgetedTemplateStats;
    var _b = useState(ShowNeedVisibilityOption.ALL), showNeedVisibilityOption = _b[0], setShowNeedVisibilityOption = _b[1];
    var applyScheduleDisplaySettings = useScheduleComponent().applyScheduleDisplaySettings;
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: "flex justify-between overflow-auto bg-[#F8F8F9] py-[9px] pl-[20px] min-h-[50px]", children: [_jsxs("div", { className: "flex items-center gap-[16px]", children: [_jsx(ScheduleNeedsSummaryInfo, { blockId: blockId }), _jsx(ScheduleCoverageSummaryInfo, { blockId: blockId })] }), _jsxs("div", { className: "flex", children: [_jsxs("div", { className: "mr-[24px] flex items-center", children: [_jsx("span", { className: "pr-[8px] font-medium", children: "Show" }), _jsx(Select, { size: "small", dropdownMatchSelectWidth: false, className: "show-needs-option-select", onChange: function (option) {
                                            setShowNeedVisibilityOption(option);
                                        }, value: showNeedVisibilityOption, children: Object.values(ShowNeedVisibilityOption).map(function (option) { return (_jsx(Select.Option, { children: option }, option)); }) })] }), _jsx(ZiraTooltip, { title: _jsxs("div", { children: ["Budgeted ", _jsx("b", { children: "ON" }), ":", _jsx("br", {}), "# of assigned template shifts /", _jsx("br", {}), "# of configured templates from applied needs", _jsx("br", {}), _jsx("br", {}), "Budgeted ", _jsx("b", { children: "OFF" }), ":", _jsx("br", {}), "Same as above but can be edited"] }), mouseEnterDelay: 0.5, children: _jsxs("div", { className: "mr-[24px] flex items-center", children: [_jsx("span", { className: "pr-[8px] font-medium", children: "Budgeted" }), _jsx(Switch, { className: "m-0", checked: isBudgetedStats, onChange: function (isBudgetedStats) {
                                                return applyScheduleDisplaySettings(blockId, {
                                                    bottomBarSettings: __assign(__assign({}, bottomBarSettings), { budgetedTemplateStats: isBudgetedStats }),
                                                });
                                            } })] }) })] })] }), _jsx(ScheduleTemplateCountStats, { blockId: blockId, widths: widths, showNeedVisibilityOption: showNeedVisibilityOption }), isConfigModalVisible && _jsx(NeedsCoverageTemplateConfigModal, { blockId: blockId })] }));
};
export default React.memo(ScheduleNeedsCoverageSummaryHeader);
